div.jqcloud {
  overflow: hidden;
  position: relative;
}

div.jqcloud span {
  padding: 0;
}

/* fonts */
div.jqcloud {
  font-family: Impact;
  font-size: 10px;
  line-height: normal;
}

div.jqcloud a {
  font-size: inherit;
  text-decoration: none;
}

div.jqcloud span.w10 { font-size: 550%; }
div.jqcloud span.w9 { font-size: 500%; }
div.jqcloud span.w8 { font-size: 450%; }
div.jqcloud span.w7 { font-size: 400%; }
div.jqcloud span.w6 { font-size: 350%; }
div.jqcloud span.w5 { font-size: 300%; }
div.jqcloud span.w4 { font-size: 250%; }
div.jqcloud span.w3 { font-size: 200%; }
div.jqcloud span.w2 { font-size: 150%; }
div.jqcloud span.w1 { font-size: 100%; }

/* colors */
div.jqcloud { color: #09f; }
div.jqcloud a { color: inherit; }
div.jqcloud a:hover { color: #0df; }
div.jqcloud a:hover { color: #0cf; }
div.jqcloud span.w10 { color: #0cf; }
div.jqcloud span.w9 { color: #0cf; }
div.jqcloud span.w8 { color: #0cf; }
div.jqcloud span.w7 { color: #39d; }
div.jqcloud span.w6 { color: #90c5f0; }
div.jqcloud span.w5 { color: #90a0dd; }
div.jqcloud span.w4 { color: #90c5f0; }
div.jqcloud span.w3 { color: #a0ddff; }
div.jqcloud span.w2 { color: #99ccee; }
div.jqcloud span.w1 { color: #aab5f0; }