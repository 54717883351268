.commentable-container {
  -webkit-transition: all 0.22s ease;
  transition: all 0.22s ease;
}
.side-comments-open {
  /*-webkit-transform: translate(-120px, 0);
  -ms-transform: translate(-120px, 0);
  transform: translate(-120px, 0);*/
}
.commentable-section {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 30px;
}
.side-comment {
  padding-bottom: 20px;
  top: 10px;
}
.side-comment .marker {
  width: 20px;
  height: 18px;
  background: #717171;
  border-radius: 2px;
  text-decoration: none;
}
.side-comment .marker:before,
.side-comment .marker span {
  content: "+";
  position: absolute;
  width: 20px;
  height: 18px;
  line-height: 16px;
  font-size: 14px;
  color: #FFF;
  text-align: center;
}
.side-comment .marker span {
  line-height: 20px;
  font-size: 12px;
}
.side-comment .marker:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -7px;
  left: 5px;
  width: 0;
  border-width: 7px 8px 0 0;
  border-style: solid;
  border-color: #717171 transparent;
}
.side-comment .marker:hover,
.side-comment.active .marker {
  background: #e4422e;
}
.side-comment .marker:hover:after,
.side-comment.active .marker:after {
  border-color: #e4422e transparent;
}
.side-comment .comments-wrapper {
  top: -22px;
  width: 200px;
  padding-bottom: 120px;
}
.side-comment.active .comments-wrapper {
  -webkit-animation: fadein 0.2s;
  animation: fadein 0.2s;
}
.side-comment.has-comments .comments-wrapper {
  top: -22px;
}
.side-comment ul.comments li,
.side-comment .comment-form {
  border: 1px solid #F2F2F0;
  border-left: 0;
  border-right: 0;
  padding: 15px 0;
  margin-top: -1px;
}
.side-comment .comment,
.side-comment .comment-box {
  font-size: 14px;
  line-height: 18px;
}
.side-comment .author-avatar {
  float: left;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.side-comment .author-avatar img {
  width: 100%;
  height: 100%;
}
.side-comment .right-of-avatar {
  float: left;
  width: 158px;
}
.side-comment .author-name {
  font-size: 15px;
  line-height: 16px;
  margin: 0 0 2px 0;
  font-weight: 700;
  text-decoration: none;
  color: #222;
}
.side-comment a.author-name:hover {
  color: #444;
}
.side-comment .action-link {
  color: #B3B3B1;
  font-size: 13px;
  text-decoration: none;
}
.side-comment .action-link:hover {
  text-decoration: none;
}
.side-comment .action-link.post .post {
  color: #89C794;
}
.side-comment .action-link.post .post:hover {
  color: #468c54;
}
.side-comment .action-link.cancel:hover,
.side-comment .action-link.delete:hover {
  color: #57AD68;
}
.side-comment .add-comment {
  color: #B3B3B1;
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  padding: 0px 8px;
  letter-spacing: 0.05em;
  text-decoration: none;
  margin-top: 10px;
  cursor: pointer;
}
.side-comment .add-comment:before {
  content: "+";
  border: 2px solid #717171;
  border-radius: 100px;
  width: 23px;
  height: 23px;
  color: #717171;
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  float: left;
  margin-right: 15px;
  letter-spacing: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.side-comment .add-comment:hover {
  text-decoration: none;
}
.side-comment .add-comment:hover {
  color: #e4422e;
}
.side-comment .add-comment:hover:before {
  border-color: #e4422e;
  color: #e4422e;
}
.side-comment .comment-box {
  outline: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}
.side-comment .actions {
  margin-top: 5px;
}
.side-comment .actions a {
  float: left;
}
.side-comment .actions .cancel:before {
  content: '\00B7';
  color: #B3B3B1;
  padding: 0 5px;
}
@media (max-width: 768px) {
  .side-comments-open {
    -webkit-transform: translate(-220px, 0);
    -ms-transform: translate(-220px, 0);
    transform: translate(-220px, 0);
  }
  .side-comment .comments-wrapper {
    width: 200px;
  }
  .side-comment .right-of-avatar {
    width: 158px;
  }
  .side-comment .marker {
    display: block;
  }
}
